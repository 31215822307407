<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="ml-0 pl-0"
  >
    <!-- <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-transit-transfer
      </v-icon> Activities
    </h2>
    <br> -->
    <div v-if="subscriptionCheck('active')">
      <v-tabs
        v-model="tab"
        color="#FF3700"
        background-color="#fff"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        class="my-tabs-background-style"
        touchless
      >
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <v-card-text>
            <component :is="item.content" />
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>

<script>
  import AppUserAllActivities from 'src/views/dashboard/appusers/AppUserAllActivities';
  import AppUserRecentActivities from 'src/views/dashboard/appusers/AppUserRecentActivities';
  import global from 'src/mixins/global';

  export default {
    name: 'AppUserActivities',

    components: {
      AppUserAllActivities,
      AppUserRecentActivities,
    },
    mixins: [global],
    data: () => ({
      tab: null,
      items: [
        { tab: 'All', content: AppUserAllActivities },
        { tab: 'Recent', content: AppUserRecentActivities },
      ],
    }),
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
  background-color: #fff !important;
}
#regular-tables {
  padding-right: 0px;
}
.v-card__text{
  padding: 5px !important;
}
</style>
